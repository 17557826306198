
import {
    CloudUploadOutlined
} from "@ant-design/icons-vue";
import {
    reactive
} from "vue";
export default {
    components: {
        CloudUploadOutlined
    },
    props: {
        formState: {
            type: Object,
            default: () => {},
        },
        visible: Boolean
    },

    data() {
        return {
            loading: false,
            imgFile: "",
            fileList: [], // 文件列表
            previewImage: "", // 预览路径
            previewVisible: false, // 开启预览
            // 表单loading
            formLoading: false,
            // 自定义内容
            deposit_page_content: '',
            // 表单规则
            rules: {
                recharge_type: [{
                    required: true,
                    type: "number",
                    message: this.$t("share.require"),
                    trigger: "change",
                }],
                user_account_code: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }],
                order_po_number: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }],
                value: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }],
                attachment: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }]
            }
        };
    },
    computed: {
        // 表单信息重新计算表
        computedFormState: {
            get() {
                const obj = reactive(Object.assign({
                    recharge_type: 0
                }, this.formState));
                obj.value =
                    this.formState.source_value != undefined ?
                    this.formState.source_value.toString() :
                    "";
                return obj;
            },
            set(val) {
                this.$emit("update:formState", val);
            },
        },
        // 重构文件列表
        getFileList: {
            get() {
                return this.fileList;
            },
            set(val) {
                this.fileList = val.slice(val.length - 1);
            },
        },
    },
    async mounted() {
        this.load_data();
    },
    methods: {
        /**
         * 加载数据
         */
        load_data() {
            this.$http.get(`/system_config/content/deposit_page_content`).then(res => {
                if (res.data.code === 0) {
                    this.deposit_page_content = res.data.data;
                }
            }).catch(() => {});
        },
        // 取消预览
        handleCancel() {
            this.previewVisible = false;
        },
        // 处理预览
        handlePreview(file) {
            this.previewVisible = true;
            this.previewImage = file.thumbUrl;
        },
        // 提交表单
        handleSubmit() {
            this.$refs.formRef
                .validate()
                .then(() => {
                    this.formLoading = true;
                    this.$http
                        .post(`/admin/deposit/update`, {
                            ...this.computedFormState,
                        })
                        .then((res) => {
                            if (res.status == 200 && res.data.code == 0) {
                                this.$emit("update:visible", false);
                                this.$emit("reload");
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        }).catch(() => {
                            this.$message.error(this.$t("操作失败"));
                        }).finally(() => {
                            this.formLoading = false;
                        })
                })
                .catch((error) => {
                    console.log("error", error);
                })
        },
        // 上传附件
        handleUpload(resFile) {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", resFile.file);

            this.imgFile = resFile.file;
            this.$http
                .post("/admin/deposit/upload_attachment", formData)
                .then((response) => {
                    if (response.status == 200 && response.data.code == 0) {
                        const res = response.data;
                        this.computedFormState.attachment = res.data;
                        this.$message.success(this.$t("操作成功"));
                        resFile.onSuccess(res.data, resFile.file);
                    }
                })
                .catch(() => {
                    resFile.onError();
                    this.$message.error(this.$t("操作失败"));
                    this.fileList = [];
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
