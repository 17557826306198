
import {
    DownloadOutlined,
    PlusOutlined,
    CalendarOutlined
} from "@ant-design/icons-vue";

import editorCharge from "./component/editor.vue";

export default {
    name: "AccountDeposit",
    components: {
        editorCharge,
        PlusOutlined,
        CalendarOutlined,
        DownloadOutlined
    },
    data() {
        return {
            where: {},
            visible: false,
            // 驳回弹窗
            reject_visible: false,
            // 填写原因
            reject_form: {},

            url: "/admin/deposit/list",
            current: {},
            rules: {
                reason: [{
                    required: true,
                    message: this.$t('必填'),
                    trigger: 'blur'
                }]
            },
            selection: [],
            form: {},
            exportLoading: false,
            // 统计
            total: {}
        };
    },
    computed: {
        columns() {
            return [{
                    key: "index",
                    width: 38,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t("客户代码"),
                    dataIndex: "user_account_code",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t('登录账号'),
                    dataIndex: 'user_account',
                    width: 120,
                    sorter: true
                }, {
                    title: this.$t('公司名称'),
                    dataIndex: 'user_company_name',
                    width: 120,
                    sorter: true
                },
                {
                    title: this.$t("平台流水号"),
                    dataIndex: "order_po_number",
                    width: 220,
                },
                {
                    title: this.$t("充值金额"),
                    dataIndex: "charge",
                    width: 120,
                    customRender: ({
                        record
                    }) => {
                        if (record.charge === undefined) {
                            return "-"
                        }
                        return `${record.charge} ${record.currency}`
                    }
                },
                {
                    title: this.$t("押金"),
                    dataIndex: "deposit_price",
                    width: 120,
                    customRender: ({
                        record
                    }) => {
                        if (record.deposit_price === undefined) {
                            return "-"
                        }
                        return `${record.deposit_price} ${record.currency}`
                    }
                },
                {
                    key: "remark",
                    title: this.$t("备注"),
                    dataIndex: "remark",
                    width: 300,
                    slots: {
                        customRender: "remark",
                    },
                    customCell: () => {
                        return {
                            style: {
                                maxWidth: "400px"
                            }
                        }
                    }
                },
                {
                    title: this.$t("附件"),
                    align: "center",
                    width: 100,
                    slots: {
                        customRender: "attachment",
                    }
                },
                {
                    title: this.$t("申请时间"),
                    dataIndex: "created",
                    align: "center",
                    width: 150
                },
                {
                    title: this.$t("确认时间"),
                    dataIndex: "audit_time",
                    align: "center",
                    width: 150,
                    customRender: ({
                        text
                    }) => text || '-'
                },
                {
                    title: this.$t("操作人"),
                    dataIndex: "creator",
                    align: "center",
                    width: 150
                },
                {
                    title: this.$t("状态"),
                    key: "status",
                    dataIndex: "status",
                    align: "center",
                    width: 100,
                    slots: {
                        customRender: "status_active"
                    }
                },
                {
                    key: "action",
                    title: this.$t("操作"),
                    dataIndex: "action",
                    align: "center",
                    fixed: "right",
                    width: 120,
                    slots: {
                        customRender: "action",
                    }
                }
            ]
        }
    },
    methods: {
        parseData(res) {
            this.total.totalcharage = res.totalcharage;
            this.total.totaldeposit = res.totaldeposit;

            return res
        },
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1,
            });
        },
        onClose() {
            this.visible = false;
        },
        manualRequest(value) {
            this.visible = true;
            this.form = value;
        },
        reject() {
            this.$refs.formRef.validate().then(() => {
                this.$http.post(`/admin/deposit/reject/${this.current.id}`, this.reject_form).then((res) => {
                    if (res.data.code === 0) {
                        this.$message.success('操作成功');
                        this.reject_visible = false;
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                });
            })

        },
        beginReject(row) {
            this.reject_form = {};
            this.reject_visible = true;
            this.current = row;
        },
        confirm(row) {
            this.$http.get(`/admin/deposit/confirm/${row.id}`).then(res => {
                if (res.data.code == 0) {
                    this.$message.success("操作成功");
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /**
         * 生成图像地址
         */
        createImageUrl(row) {
            return process.env.VUE_APP_STS_FILE_URL + row.attachment;
        },
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http.get(`/admin/deposit/recharge_export/`, {
                params: this.where
            }).then(res => {
                this.exportLoading = false;

                if (res.data.code === 0) {
                    const blob = this.base64ToBlob(res.data.data, 'application/vnd.ms-excel');
                    const fileName = `Deposit-${new Date().getTime()}.xls`;

                    if ("download" in document.createElement("a")) {
                        // 非IE下载
                        const el = document.createElement("a");

                        el.download = fileName;
                        el.style.display = "none";
                        el.href = URL.createObjectURL(blob);
                        document.body.appendChild(el);

                        el.click();

                        URL.revokeObjectURL(el.href);
                        document.body.removeChild(el);
                    } else {
                        // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.exportLoading = false;
                this.$message.error(e.message);
            });
        },
        base64ToBlob(text, content_type) {
            var byteString = atob(text);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], {
                type: content_type
            });
        }
    }
};
